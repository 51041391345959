.home__title{
  margin-bottom: 15px;
  font-size: 3.2em;
  margin-top: 0;
  line-height: 1.2em;
  @media screen and (max-width: 375px){
    font-size: 2em;
    letter-spacing: 0;
  }
}

.home__strapline{
  font-weight: 200;
  font-size: 1.5em;
  color: #878787;
}
