nav{
  text-align: center;
  padding: 30px 0 0;
  a{
    margin: 0 15px;
    padding-bottom: 2px;
    color: #919191;
    font-weight: 500;
    letter-spacing: .2pt;
    text-decoration: none;
  }
}

.content-wrapper{
  padding: 0 20px 20px;
  width: 100%;
  display: block;
  margin: 10vh auto;
  @media screen and (max-width: 375px){
    padding: 0 10px 20px;
  }
}

.home__header-image{
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-bottom: 24px;
}
