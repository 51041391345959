.library__book-single{
  margin-bottom: 180px;
  text-align: center;
  vertical-align: bottom;
  position: relative;
}

.library__book-image__wrapper{
  padding: 0 20px;
  text-align: center;
}

.library__book-image{
  max-width: 80%;
  height: auto;
  box-shadow: 0 1px 15px rgba(0,0,0,0.1);

}

.library__book-title{
  margin-top: 10px;
  font-weight: 600;
  display: block;
  position: absolute;
  width: 100%;
  left: 0px;
}

.library__book-author{
  display: block;
  color: #878787;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0px;
  font-weight: 300;
}
