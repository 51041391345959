body {
  margin: 0;
}

.wrap {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 840px;
  box-sizing: border-box;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:before, .row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}

*[class*='col__'] {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px;
  margin-bottom: 20px;
}

@media (min-width: 769px) {
  .col__0-12 {
    width: 0%;
  }

  .col__0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__0-12 {
    margin-left: 0%;
  }

  .col__1-12 {
    width: 8.33333%;
  }

  .col__1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__1-12 {
    margin-left: 8.33333%;
  }

  .col__2-12 {
    width: 16.66667%;
  }

  .col__2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__2-12 {
    margin-left: 16.66667%;
  }

  .col__3-12 {
    width: 25%;
  }

  .col__3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__3-12 {
    margin-left: 25%;
  }

  .col__4-12 {
    width: 33.33333%;
  }

  .col__4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__4-12 {
    margin-left: 33.33333%;
  }

  .col__5-12 {
    width: 41.66667%;
  }

  .col__5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__5-12 {
    margin-left: 41.66667%;
  }

  .col__6-12 {
    width: 50%;
  }

  .col__6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__6-12 {
    margin-left: 50%;
  }

  .col__7-12 {
    width: 58.33333%;
  }

  .col__7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__7-12 {
    margin-left: 58.33333%;
  }

  .col__8-12 {
    width: 66.66667%;
  }

  .col__8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__8-12 {
    margin-left: 66.66667%;
  }

  .col__9-12 {
    width: 75%;
  }

  .col__9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__9-12 {
    margin-left: 75%;
  }

  .col__10-12 {
    width: 83.33333%;
  }

  .col__10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__10-12 {
    margin-left: 83.33333%;
  }

  .col__11-12 {
    width: 91.66667%;
  }

  .col__11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__11-12 {
    margin-left: 91.66667%;
  }

  .col__12-12 {
    width: 100%;
  }

  .col__12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__12-12 {
    margin-left: 100%;
  }

  .col__lg-0-12 {
    width: 0%;
    margin-left: 0;
  }

  .col__lg-0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__lg-0-12 {
    margin-left: 0% !important;
  }

  .col__lg-1-12 {
    width: 8.33333%;
    margin-left: 0;
  }

  .col__lg-1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__lg-1-12 {
    margin-left: 8.33333% !important;
  }

  .col__lg-2-12 {
    width: 16.66667%;
    margin-left: 0;
  }

  .col__lg-2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__lg-2-12 {
    margin-left: 16.66667% !important;
  }

  .col__lg-3-12 {
    width: 25%;
    margin-left: 0;
  }

  .col__lg-3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__lg-3-12 {
    margin-left: 25% !important;
  }

  .col__lg-4-12 {
    width: 33.33333%;
    margin-left: 0;
  }

  .col__lg-4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__lg-4-12 {
    margin-left: 33.33333% !important;
  }

  .col__lg-5-12 {
    width: 41.66667%;
    margin-left: 0;
  }

  .col__lg-5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__lg-5-12 {
    margin-left: 41.66667% !important;
  }

  .col__lg-6-12 {
    width: 50%;
    margin-left: 0;
  }

  .col__lg-6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__lg-6-12 {
    margin-left: 50% !important;
  }

  .col__lg-7-12 {
    width: 58.33333%;
    margin-left: 0;
  }

  .col__lg-7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__lg-7-12 {
    margin-left: 58.33333% !important;
  }

  .col__lg-8-12 {
    width: 66.66667%;
    margin-left: 0;
  }

  .col__lg-8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__lg-8-12 {
    margin-left: 66.66667% !important;
  }

  .col__lg-9-12 {
    width: 75%;
    margin-left: 0;
  }

  .col__lg-9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__lg-9-12 {
    margin-left: 75% !important;
  }

  .col__lg-10-12 {
    width: 83.33333%;
    margin-left: 0;
  }

  .col__lg-10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__lg-10-12 {
    margin-left: 83.33333% !important;
  }

  .col__lg-11-12 {
    width: 91.66667%;
    margin-left: 0;
  }

  .col__lg-11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__lg-11-12 {
    margin-left: 91.66667% !important;
  }

  .col__lg-12-12 {
    width: 100%;
    margin-left: 0;
  }

  .col__lg-12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__lg-12-12 {
    margin-left: 100% !important;
  }

  .hidden__lg {
    display: none;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .col__0-12 {
    width: 0%;
  }

  .col__0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__0-12 {
    margin-left: 0%;
  }

  .col__1-12 {
    width: 8.33333%;
  }

  .col__1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__1-12 {
    margin-left: 8.33333%;
  }

  .col__2-12 {
    width: 16.66667%;
  }

  .col__2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__2-12 {
    margin-left: 16.66667%;
  }

  .col__3-12 {
    width: 25%;
  }

  .col__3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__3-12 {
    margin-left: 25%;
  }

  .col__4-12 {
    width: 33.33333%;
  }

  .col__4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__4-12 {
    margin-left: 33.33333%;
  }

  .col__5-12 {
    width: 41.66667%;
  }

  .col__5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__5-12 {
    margin-left: 41.66667%;
  }

  .col__6-12 {
    width: 50%;
  }

  .col__6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__6-12 {
    margin-left: 50%;
  }

  .col__7-12 {
    width: 58.33333%;
  }

  .col__7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__7-12 {
    margin-left: 58.33333%;
  }

  .col__8-12 {
    width: 66.66667%;
  }

  .col__8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__8-12 {
    margin-left: 66.66667%;
  }

  .col__9-12 {
    width: 75%;
  }

  .col__9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__9-12 {
    margin-left: 75%;
  }

  .col__10-12 {
    width: 83.33333%;
  }

  .col__10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__10-12 {
    margin-left: 83.33333%;
  }

  .col__11-12 {
    width: 91.66667%;
  }

  .col__11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__11-12 {
    margin-left: 91.66667%;
  }

  .col__12-12 {
    width: 100%;
  }

  .col__12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__12-12 {
    margin-left: 100%;
  }

  .col__md-0-12 {
    width: 0%;
    margin-left: 0;
  }

  .col__md-0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__md-0-12 {
    margin-left: 0% !important;
  }

  .col__md-1-12 {
    width: 8.33333%;
    margin-left: 0;
  }

  .col__md-1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__md-1-12 {
    margin-left: 8.33333% !important;
  }

  .col__md-2-12 {
    width: 16.66667%;
    margin-left: 0;
  }

  .col__md-2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__md-2-12 {
    margin-left: 16.66667% !important;
  }

  .col__md-3-12 {
    width: 25%;
    margin-left: 0;
  }

  .col__md-3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__md-3-12 {
    margin-left: 25% !important;
  }

  .col__md-4-12 {
    width: 33.33333%;
    margin-left: 0;
  }

  .col__md-4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__md-4-12 {
    margin-left: 33.33333% !important;
  }

  .col__md-5-12 {
    width: 41.66667%;
    margin-left: 0;
  }

  .col__md-5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__md-5-12 {
    margin-left: 41.66667% !important;
  }

  .col__md-6-12 {
    width: 50%;
    margin-left: 0;
  }

  .col__md-6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__md-6-12 {
    margin-left: 50% !important;
  }

  .col__md-7-12 {
    width: 58.33333%;
    margin-left: 0;
  }

  .col__md-7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__md-7-12 {
    margin-left: 58.33333% !important;
  }

  .col__md-8-12 {
    width: 66.66667%;
    margin-left: 0;
  }

  .col__md-8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__md-8-12 {
    margin-left: 66.66667% !important;
  }

  .col__md-9-12 {
    width: 75%;
    margin-left: 0;
  }

  .col__md-9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__md-9-12 {
    margin-left: 75% !important;
  }

  .col__md-10-12 {
    width: 83.33333%;
    margin-left: 0;
  }

  .col__md-10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__md-10-12 {
    margin-left: 83.33333% !important;
  }

  .col__md-11-12 {
    width: 91.66667%;
    margin-left: 0;
  }

  .col__md-11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__md-11-12 {
    margin-left: 91.66667% !important;
  }

  .col__md-12-12 {
    width: 100%;
    margin-left: 0;
  }

  .col__md-12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__md-12-12 {
    margin-left: 100% !important;
  }

  .hidden__md {
    display: none;
  }
}
@media (max-width: 480px) {
  .col__0-12 {
    width: 0%;
  }

  .col__0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__0-12 {
    margin-left: 0%;
  }

  .col__1-12 {
    width: 8.33333%;
  }

  .col__1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__1-12 {
    margin-left: 8.33333%;
  }

  .col__2-12 {
    width: 16.66667%;
  }

  .col__2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__2-12 {
    margin-left: 16.66667%;
  }

  .col__3-12 {
    width: 25%;
  }

  .col__3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__3-12 {
    margin-left: 25%;
  }

  .col__4-12 {
    width: 33.33333%;
  }

  .col__4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__4-12 {
    margin-left: 33.33333%;
  }

  .col__5-12 {
    width: 41.66667%;
  }

  .col__5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__5-12 {
    margin-left: 41.66667%;
  }

  .col__6-12 {
    width: 50%;
  }

  .col__6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__6-12 {
    margin-left: 50%;
  }

  .col__7-12 {
    width: 58.33333%;
  }

  .col__7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__7-12 {
    margin-left: 58.33333%;
  }

  .col__8-12 {
    width: 66.66667%;
  }

  .col__8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__8-12 {
    margin-left: 66.66667%;
  }

  .col__9-12 {
    width: 75%;
  }

  .col__9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__9-12 {
    margin-left: 75%;
  }

  .col__10-12 {
    width: 83.33333%;
  }

  .col__10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__10-12 {
    margin-left: 83.33333%;
  }

  .col__11-12 {
    width: 91.66667%;
  }

  .col__11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__11-12 {
    margin-left: 91.66667%;
  }

  .col__12-12 {
    width: 100%;
  }

  .col__12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__12-12 {
    margin-left: 100%;
  }

  .col__sm-0-12 {
    width: 0%;
    margin-left: 0;
  }

  .col__sm-0-12__center {
    width: 0%;
    margin-left: 50%;
    display: block;
  }

  .offset__sm-0-12 {
    margin-left: 0% !important;
  }

  .col__sm-1-12 {
    width: 8.33333%;
    margin-left: 0;
  }

  .col__sm-1-12__center {
    width: 8.33333%;
    margin-left: 45.83333%;
    display: block;
  }

  .offset__sm-1-12 {
    margin-left: 8.33333% !important;
  }

  .col__sm-2-12 {
    width: 16.66667%;
    margin-left: 0;
  }

  .col__sm-2-12__center {
    width: 16.66667%;
    margin-left: 41.66667%;
    display: block;
  }

  .offset__sm-2-12 {
    margin-left: 16.66667% !important;
  }

  .col__sm-3-12 {
    width: 25%;
    margin-left: 0;
  }

  .col__sm-3-12__center {
    width: 25%;
    margin-left: 37.5%;
    display: block;
  }

  .offset__sm-3-12 {
    margin-left: 25% !important;
  }

  .col__sm-4-12 {
    width: 33.33333%;
    margin-left: 0;
  }

  .col__sm-4-12__center {
    width: 33.33333%;
    margin-left: 33.33333%;
    display: block;
  }

  .offset__sm-4-12 {
    margin-left: 33.33333% !important;
  }

  .col__sm-5-12 {
    width: 41.66667%;
    margin-left: 0;
  }

  .col__sm-5-12__center {
    width: 41.66667%;
    margin-left: 29.16667%;
    display: block;
  }

  .offset__sm-5-12 {
    margin-left: 41.66667% !important;
  }

  .col__sm-6-12 {
    width: 50%;
    margin-left: 0;
  }

  .col__sm-6-12__center {
    width: 50%;
    margin-left: 25%;
    display: block;
  }

  .offset__sm-6-12 {
    margin-left: 50% !important;
  }

  .col__sm-7-12 {
    width: 58.33333%;
    margin-left: 0;
  }

  .col__sm-7-12__center {
    width: 58.33333%;
    margin-left: 20.83333%;
    display: block;
  }

  .offset__sm-7-12 {
    margin-left: 58.33333% !important;
  }

  .col__sm-8-12 {
    width: 66.66667%;
    margin-left: 0;
  }

  .col__sm-8-12__center {
    width: 66.66667%;
    margin-left: 16.66667%;
    display: block;
  }

  .offset__sm-8-12 {
    margin-left: 66.66667% !important;
  }

  .col__sm-9-12 {
    width: 75%;
    margin-left: 0;
  }

  .col__sm-9-12__center {
    width: 75%;
    margin-left: 12.5%;
    display: block;
  }

  .offset__sm-9-12 {
    margin-left: 75% !important;
  }

  .col__sm-10-12 {
    width: 83.33333%;
    margin-left: 0;
  }

  .col__sm-10-12__center {
    width: 83.33333%;
    margin-left: 8.33333%;
    display: block;
  }

  .offset__sm-10-12 {
    margin-left: 83.33333% !important;
  }

  .col__sm-11-12 {
    width: 91.66667%;
    margin-left: 0;
  }

  .col__sm-11-12__center {
    width: 91.66667%;
    margin-left: 4.16667%;
    display: block;
  }

  .offset__sm-11-12 {
    margin-left: 91.66667% !important;
  }

  .col__sm-12-12 {
    width: 100%;
    margin-left: 0;
  }

  .col__sm-12-12__center {
    width: 100%;
    margin-left: 0%;
    display: block;
  }

  .offset__sm-12-12 {
    margin-left: 100% !important;
  }

  .hidden__sm {
    display: none;
  }
}
