.blog-index__page-h1{
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.2em;
}

.blog-index__post{
  margin-bottom: 100px;
}

.blog-index__title{
  font-size: 3.2em;
  margin-bottom: 15px;
  margin-top: 0;
  line-height: 1.2em;
  @media screen and (max-width: 375px){
    font-size: 2em;
    letter-spacing: 0;
  }
  .blog-index__title__post-link{
    color: #da3877;
    text-decoration: none;
    letter-spacing: -0.1pt;
    &:hover{
      color: #ab2056;
    }
  }
}

.blog-index__date{
  font-weight: 200;
  font-size: 1.5em;
  color: #878787;
}

.post-title{
  margin-bottom: 15px;
}

.post-meta{
  color: #878787;
  font-weight: 200;
}

.blog__content{
  img{
    width: 100%;
    margin: 30px 0 0;
  }
  .blog__caption{
    color: #878787;
    font-size: .9em;
  }
  .blog__img--lg{
    margin: 30px 0;
    width: 810px;
    margin-left: -280px;
    @media screen and (max-width: 880px){
      width: 133%;
      margin-left: -33%;
    }
    @media screen and (max-width: 768px){
      width: 100%;
      margin-left: 0;
    }
  }
  .blog__img--lg__caption{
    color: #878787;
    font-size: .9em;
  }
}

.blog__footer{
  margin: 140px 0 0 0;
  padding: 30px 0;
  border-top: 1px solid #dedede;
}

.blog__footer__related__wrapper{
  .blog__footer__related{
    box-shadow: 0 3px 20px rgba(0,0,0,0);
    transition: all 100ms linear;
  }
}

.blog__footer__related{
  position: relative;
  line-height: 0;
}

.blog__footer__related__img{
  width: 100%;
  height: auto;
}

.blog__footer__related__bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.45));
  opacity: 0.8;
  transition: all 200ms ease-in-out;
}

.blog__footer__related__title{
  position: absolute;
  bottom: 0;
  margin: 20px 18% 15px 24px;
  line-height: 2em;
  transition: all 100ms ease-in-out;
  > span{
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0,0,0,0.2);
  }
}

.blog__footer__related__wrapper:hover{
  .blog__footer__related{
    // box-shadow: 0 3px 20px rgba(0,0,0,0.18);
    transition: all 100ms linear;
  }
  .blog__footer__related__title{
    bottom: 4px;
    transition: all 200ms ease-in-out;
  }
  .blog__footer__related__bg{
    opacity: 0.95;
    transition: all 200ms ease-in-out;
  }
}

blockquote{
  margin: 2em 0 2em 0;
  padding-left: 40px;
  border-left: 4px solid #dedede;
  p{
    color: #878787;
    font-style: italic;
    margin: 0 0;
    top: -10px;
  }
}

figure.highlight{
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  pre{
    position: relative;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 10px 20px;
    border: 1px solid #eeeeee;
    background-color: #fcfcfc;
    border-radius: 4px;
  }
}
